import { ContentType, HttpClient, RequestParams } from '../http-client';

export class CompanySetting<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getCompanySettingCrewRank = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanySettingCrewRank = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  deleteCompanySettingCrewRankById = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-rank/${data.id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  getCompanySettingDefaultCrewStatus = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/autofill/settings/status`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanySettingCrewStatus = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-status`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanySettingCrewStatus = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-status`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });

  deleteCompanySettingCrewStatus = (id: number, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-status/${id}`,
      method: 'DELETE',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanySettingCrewSchedule = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-schedule`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  updateCompanySettingCrewSchedule = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/setting/crew-schedule`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      body: data,
      format: 'json',
      ...params,
    });
}
